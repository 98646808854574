@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/MaterialIcons_v140.woff2") format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

.material-icons-sm {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

button.btn-sm.btn-with-icon
{
  padding: 5px 6px 2px 6px;
}

button.btn-sm.btn-with-icon > span.material-icons-sm
{
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.accnt-source-text
{
	font-size: 85%;
}

p.accnt-exc-list-subsource span,
p.accnt-exc-parser-subsource span
{
	display: inline-block;
	font-size: 70%;
}

p.accnt-exc-list-subsource,
p.accnt-exc-parser-subsource
{
	margin-bottom: 0px !important;
}

.accnt-source-text .span
{
	max-width: 150px;
}

td.accnt-exc-list-source-col  { }
td.accnt-exc-list-date-col    { }
td.accnt-exc-list-sum-col     { } 
td.accnt-exc-list-purp-col    { }
td.accnt-exc-list-org-col     { min-width: 150px; }
td.accnt-exc-list-comment-col { }

td.accnt-exc-list-purp-col p.text-start { padding-left: 5px; }

.accnt-exc-incoming-row-prihod { background-color: #b1f7ad; }
.accnt-exc-incoming-row-rashod { background-color: #ff9494; }

table.accnt-exc-list-maintable th,
table.accnt-exc-list-maintable td,

table.accnt-exc-parser-maintable th,
table.accnt-exc-parser-maintable td
{
  padding: 0px;
  margin: 0px;
  font-size: 90%;
}

table.accnt-exc-parser-maintable td.accnt-exc-parser-date-col
{
  text-align: center;
}

table.accnt-exc-parser-maintable th
{
  text-align: center;
}

td.accnt-exc-parser-sum-col
{
  text-align: right;
  padding-right: 5px !important;
}

td.accnt-exc-parser-actions-col
{
  text-align: center;
}

td.accnt-exc-parser-purp-col p.text-start { padding-left: 5px; }
td.accnt-exc-parser-org-col { text-align: center; }

table.accnt-exc-list-maintable td p
{
  margin-bottom: 0px;
}

table.accnt-exc-list-maintable td textarea
{
  font-size: 90%;
}

td.accnt-exc-list-sum-col p
{
  display: inline-block;
  padding-right: 5px;
}

td.accnt-exc-list-sum-col { text-align: right; }

div.accnt-incoming-maincontainer
{
  /*max-height: 650px;
  min-height: 400px;*/
  height: calc(100vh - 310px);
}

div.accnt-outcoming-maincontainer
{
  /*max-height: 650px;
  min-height: 400px;*/
  height: calc(100vh - 275px);
}

div.accnt-exc-list-maincontainer
{
  height: calc(100vh - 350px);
}

table.accnt-desk-toptable { min-width: 1200px; }
table.accnt-desk-toptable th { text-align: center; }

table.accnt-desk-toptable td.col-metric { text-align: left; }
table.accnt-desk-toptable td.col-value  { text-align: right; padding-right: 5px; }


div.accnt-exc-history-maincontainer
{
  height: calc(100vh - 360px);
}

table.accnt-exc-history-maintable tr,
table.accnt-exc-history-maintable td
{
  font-size: 90%;
}

table.accnt-exc-history-maintable th
{
  text-align: center;
}

table.accnt-exc-history-maintable td.sum-column
{
  text-align: right;
  padding-right: 5px !important;
}

table.rd-accounting { font-size: 85%; }
table.rd-accounting th { text-align: center; font-weight: bold; }
/* table.rd-accounting td {  } */
table.rd-accounting td.col-value, table.rd-accounting td.sum-column { text-align: right; padding-right: 5px !important; }
table.rd-accounting td.col-centred { text-align: center; }
table.rd-accounting td select.form-select-sm { line-height: 1 !important; }
/* calc-by-click */
.calc-by-click { font-weight: bold; }


.nu-dr-info {
  align-items: flex-end !important;
  display: flex !important;
}

.p-multiselect.small .p-multiselect-label
{
  padding: 3px 8px 3px 8px;
}

.p-multiselect.wide
{
  width: 100%;
}

.p-inputtext
{
  height: 35px;
}

.p-custom-width
{
  width: 110px;
}